import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useRouteMatch } from 'react-router-dom'
import * as R from 'ramda'
import styled from 'styled-components'

import PATHS from 'utils/paths'
import { LOCAL_STORAGE_KEYS } from 'utils/storage'

import LocalStorageService from 'services/LocalStorageService'

import { getIsImpersonate, getStudent } from 'modules/auth/ducks/selectors'
import { fetchAllBooksRoutine } from 'modules/books/ducks/actions'
import {
  areAllFlashcardsOff,
  getBooksNavigation,
  hasOnlyTestBundledBooks
} from 'modules/books/ducks/selectors'
import {
  getCurrentCourse,
  getCoursesList,
  getHasAllCoursesExpired
} from 'modules/courses/ducks/selectors'

import { previewStudentsEmail } from 'utils/user'
import { isDateBeforeToday } from 'utils/date'
import { isNilOrEmpty, isNotNilOrEmpty } from 'utils/ramda'

import {
  TopNavigation,
  ExamEditorIcon,
  CalendarIcon,
  BooksContainedIcon,
  LogoutIcon,
  NotesIcon,
  VideoCameraIcon,
  FlashcardsIcon,
  DashboardIcon,
  VolumeControl,
  Button,
  CoursesIcon,
  TrophyIcon,
  ToDoListIcon
  // OnboardingIcon
} from 'examkrackers-components'
import { TetrisIcon } from 'examkrackers-amino-acid-game'
import LearningTimer from 'modules/learningTime/components/LearningTimer'
import TotalSaltyBucks from 'modules/saltyBucks/components/TotalSaltyBucks'
import ProfileDropdown from './ProfileDropdown'
import NotificationFreeTrial from 'modules/courses/components/NotificationFreeTrial'
import ModalEditUsername from 'modules/student/components/ModalEditUsername'

import { Course, RootReducer } from 'types'
import { fetchCurrentCourseRoutine } from 'modules/courses/ducks/actions'
import { COURSE_TYPES } from 'utils/courses'
import ModalExpiredCourse from 'modules/courses/components/ModalExpiredCourse'
import { studentLogoutRoutine } from 'modules/auth/ducks/actions'
import NotificationImpersonate from './NotificationImpersonate'
import NotificationDropdown from '../modules/notifications/components/NotificationDropdown'
import SettingsDropdown from './SettingsDropdown'
import { fetchNotificationsRoutine } from 'modules/notifications/ducks/actions'
import { fetchVideosByCategory } from 'services/VideosService'

import AnalyticsService from 'services/AnalyticsService'
import { ANALYTICS_EVENTS } from 'utils/analytics'

export const AuthNav = (): JSX.Element => {
  const [reviewVids, setReviewVids] = React.useState<any>(null)

  const { t } = useTranslation()
  const { path } = useRouteMatch()
  const { push, location } = useHistory()
  const { pathname } = location
  const dispatch = useDispatch()
  const user = useSelector(getStudent)
  const studentId = R.propOr('', 'id', user)
  const booksNavigation = useSelector((state: RootReducer) =>
    getBooksNavigation(state, push)
  )
  const hideFlashcardsPage = useSelector(areAllFlashcardsOff)
  const currentCourse: Course = useSelector(getCurrentCourse)
  const isPrioriDays = R.propOr(false, 'prioridays', currentCourse)
  const isExamAt = R.propOr(false, 'exam_at', currentCourse)
  const isSubscriptionCourse = currentCourse.type === 'on_demand'

  const isOnDemandCourse =
    currentCourse.type === 'on_demand' ||
    currentCourse.type === 'free_trial' ||
    currentCourse.type === 'live_course'
  const accessibleTo = R.propOr('', 'accessible_to', currentCourse)
  const isImpersonate = useSelector(getIsImpersonate)
  const hasAllCoursesExpired = useSelector(getHasAllCoursesExpired)

  const fetchNotifications = React.useCallback(
    payload => dispatch(fetchNotificationsRoutine(payload)),
    [dispatch]
  )
  const courses: Course[] = useSelector(getCoursesList)
  const isExpired = isDateBeforeToday(accessibleTo)
  const isFreeTrial = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.freeTrial)
  )(currentCourse)
  const isCalendarAvailable =
    currentCourse.original?.is_calendar_enabled || false

  const isLiveCourse = R.pipe(
    R.propOr('', 'type'),
    R.equals(COURSE_TYPES.live)
  )(currentCourse)

  const handleLogout = () => {
    dispatch(studentLogoutRoutine())
  }

  const onlyTestBundleBooks = useSelector(hasOnlyTestBundledBooks)
  const isSelectCoursePage = path === PATHS.selectCourse
  const isLoadingDashboardPage = path === PATHS.dashboardLoading
  const hideNavElements = isSelectCoursePage || isLoadingDashboardPage
  const isPreviewStudentsEmail = user.email === previewStudentsEmail
  const hasCourse = R.pipe(R.propOr('', 'id'), isNotNilOrEmpty)(currentCourse)

  const fetchAllBooks = React.useCallback(
    () => dispatch(fetchAllBooksRoutine()),
    [dispatch]
  )

  const fetchCurrentCourse = React.useCallback(
    () => dispatch(fetchCurrentCourseRoutine()),
    [dispatch]
  )
  const courseId = LocalStorageService.get(LOCAL_STORAGE_KEYS.studentCourseId)

  const currentBookCourseId = currentCourse.book_course_id

  const fetchReviewVideos = () => {
    const handleSuccess = response => {
      setReviewVids(response.data)
    }
    const handleErr = response => {
      console.error(response)
    }

    fetchVideosByCategory({
      category: `review&filter[course_id]=${currentBookCourseId}`
    })
      .then(handleSuccess)
      .catch(handleErr)
  }
  React.useEffect(() => {
    isNotNilOrEmpty(currentBookCourseId) ? fetchReviewVideos() : null
  }, [currentBookCourseId])

  React.useEffect(() => {
    if (isNotNilOrEmpty(courseId) && isNilOrEmpty(booksNavigation)) {
      fetchAllBooks()
    }
  }, [user, path])

  React.useEffect(() => {
    if (isPreviewStudentsEmail && isNilOrEmpty(booksNavigation)) {
      fetchAllBooks()
    }
  }, [user])

  useEffect(() => {
    if (
      path !== PATHS.selectCourse &&
      isNilOrEmpty(courseId) &&
      hasCourse &&
      !isPreviewStudentsEmail
    ) {
      push(PATHS.selectCourse)
    }

    if (path !== PATHS.selectCourse && isNotNilOrEmpty(courseId)) {
      fetchCurrentCourse()
    }
  }, [pathname, user])

  useEffect(() => {
    studentId && fetchNotifications({ studentId })
  }, [studentId])

  const [openModalEditUsername, setOpenModalEditUsername] =
    React.useState(false)

  const handleOpenModalEditUsername = () => setOpenModalEditUsername(true)

  const gamesNavigation = [
    {
      label: t('navigation.saltyBlox'),
      // icon: <TetrisIcon width='20px' height='20px' />,
      onClick: () => {
        push(PATHS.gameSaltyBlox)
        document.documentElement.requestFullscreen()
      },
      url: PATHS.gameSaltyBlox
    },
    {
      label: t('navigation.saltyBloxRespiration'),
      // icon: <TetrisIcon width='20px' height='20px' />,
      onClick: () => {
        push(PATHS.gameRespiration)
        document.documentElement.requestFullscreen()
      },
      url: PATHS.gameRespiration
    },
    {
      label: t('navigation.hangman'),
      onClick: () => {
        push(PATHS.gameHangman)
        document.documentElement.requestFullscreen()
      },
      url: PATHS.gameHangman
    }
  ]

  const logoutLinks = [
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const flashcardLinks = hideFlashcardsPage
    ? []
    : [
        {
          label: t('navigation.flashcards'),
          icon: <FlashcardsIcon width='20px' height='20px' />,
          url: PATHS.flashcardsBoxes
        }
      ]

  const examStudentLinks = [
    {
      label: t('navigation.exams'),
      icon: <ExamEditorIcon width='20px' height='20px' />,
      url: PATHS.exams
    },
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const expiredCoursesLinks = [
    {
      label: t('navigation.exams'),
      icon: <ExamEditorIcon width='20px' height='20px' />,
      url: PATHS.exams
    },
    {
      label: t('courses.changeCTA'),
      icon: <CoursesIcon width='20px' height='20px' />,
      url: PATHS.selectCourse
    },
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const courseStudentLinks = [
    {
      label: t('navigation.dashboard'),
      icon: <DashboardIcon width='20px' height='20px' />,
      url: PATHS.dashboard
    },
    {
      label: t('navigation.books'),
      icon: <BooksContainedIcon width='20px' height='20px' />,
      nextLevel: booksNavigation,
      url: '#'
    },
    {
      label: t('navigation.videos'),
      icon: <VideoCameraIcon width='20px' height='20px' />,
      url: PATHS.videos
    },
    ...flashcardLinks,
    {
      label: t('navigation.notes'),
      icon: <NotesIcon width='20px' height='20px' />,
      url: PATHS.notes
    },
    {
      label: t('navigation.exams'),
      icon: <ExamEditorIcon width='20px' height='20px' />,
      url: PATHS.exams
    },
    isFreeTrial
      ? isExamAt
        ? {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendar
          }
        : {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendarSetupFreeTrial
          }
      : isLiveCourse
      ? isExamAt
        ? {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendar
          }
        : {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendarSetupExpiration
          }
      : isSubscriptionCourse
      ? isPrioriDays
        ? {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendar
          }
        : {
            label: t('navigation.calendar'),
            icon: <CalendarIcon width='20px' height='20px' />,
            url: PATHS.calendarSetup
          }
      : {
          label: t('navigation.calendar'),
          icon: <CalendarIcon width='20px' height='20px' />,
          url: PATHS.calendar
        },
    {
      label: 'Games',
      icon: <TetrisIcon width='20px' height='20px' />,
      nextLevel: gamesNavigation
    },
    {
      label: t('navigation.leaderboard'),
      icon: <TrophyIcon width='20px' height='20px' />,
      url: PATHS.leaderboard
    },
    {
      label: t('navigation.topics'),
      icon: <ToDoListIcon width='20px' height='20px' />,
      url: PATHS.courseTopics
    },
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]
  const courseStudentLinksWithoutCalendar = [
    {
      label: t('navigation.dashboard'),
      icon: <DashboardIcon width='20px' height='20px' />,
      url: PATHS.dashboard
    },
    {
      label: t('navigation.books'),
      icon: <BooksContainedIcon width='20px' height='20px' />,
      nextLevel: booksNavigation,
      url: '#'
    },
    {
      label: t('navigation.videos'),
      icon: <VideoCameraIcon width='20px' height='20px' />,
      url: PATHS.videos
    },
    ...flashcardLinks,
    {
      label: t('navigation.notes'),
      icon: <NotesIcon width='20px' height='20px' />,
      url: PATHS.notes
    },
    {
      label: t('navigation.exams'),
      icon: <ExamEditorIcon width='20px' height='20px' />,
      url: PATHS.exams
    },
    {
      label: 'Games',
      icon: <TetrisIcon width='20px' height='20px' />,
      nextLevel: gamesNavigation
    },
    {
      label: t('navigation.leaderboard'),
      icon: <TrophyIcon width='20px' height='20px' />,
      url: PATHS.leaderboard
    },
    {
      label: t('navigation.topics'),
      icon: <ToDoListIcon width='20px' height='20px' />,
      url: PATHS.courseTopics
    },
    // {
    //   label: t('navigation.onboarding'),
    //   icon: <OnboardingIcon width='20px' height='20px' />,
    //   url: PATHS.onboarding
    // },
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const TestBundleWithVidsLinks = [
    {
      label: t('navigation.exams'),
      icon: <ExamEditorIcon width='20px' height='20px' />,
      url: PATHS.exams
    },
    {
      label: t('navigation.videos'),
      icon: <VideoCameraIcon width='20px' height='20px' />,
      url: PATHS.videos
    },
    {
      label: t('navigation.logout'),
      icon: <LogoutIcon width='20px' height='20px' />,
      url: PATHS.logout
    }
  ]

  const getNotification = () => {
    switch (true) {
      case isImpersonate:
        return <NotificationImpersonate />
      case isFreeTrial:
        return <NotificationFreeTrial />
      default:
        return ''
    }
  }

  const getCourseLinks = () => {
    switch (true) {
      case hideNavElements || isPreviewStudentsEmail:
        return []
      case isSelectCoursePage && hasAllCoursesExpired:
        return examStudentLinks
      case !isCalendarAvailable:
        return courseStudentLinksWithoutCalendar
      case onlyTestBundleBooks:
        return examStudentLinks
      case isOnDemandCourse:
        return courseStudentLinks
      default:
        return courseStudentLinks
    }
  }

  const getExamLinks = () => {
    switch (true) {
      case onlyTestBundleBooks && isNotNilOrEmpty(reviewVids):
        return TestBundleWithVidsLinks
      case isSelectCoursePage && !hasAllCoursesExpired:
        return logoutLinks
      case isPreviewStudentsEmail:
        return []
      case !isSelectCoursePage && hasAllCoursesExpired:
        return expiredCoursesLinks
      default:
        return examStudentLinks
    }
  }

  const handleClickOnMenuButton = () => {
    if (isNotNilOrEmpty(currentCourse.id)) {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.clickOnMenuButton, {
        'Course type': currentCourse?.type || false,
        'Course name': currentCourse?.title || false,
        'Course expiration date': currentCourse?.accessible_to || false
      })
    } else {
      AnalyticsService(user).logEvent(ANALYTICS_EVENTS.clickOnMenuButton, {
        'Course type': false,
        'Course name': false,
        'Course expiration date': false,
        'App place': 'select-course'
      })
    }
  }

  const courseStudentNavProps = hasCourse
    ? {
        redirectHandler: push,
        clickableLogo: true,
        logoOnClick: () => push(PATHS.dashboard),
        showCrackUniversityLogo: R.propOr(false, 'has_courses', user),
        notification: getNotification(),
        greeting: '',
        menu: t('navigation.menu'),
        links: onlyTestBundleBooks ? getExamLinks() : getCourseLinks(),
        onMenuOpen: () => handleClickOnMenuButton(),
        navRightElements: isLoadingDashboardPage ? (
          ''
        ) : isSelectCoursePage ? (
          <Button
            variant='contained'
            onClick={handleLogout}
            size='small'
            color='tertiary'
          >
            Logout
          </Button>
        ) : (
          [
            <NotificationDropdown key='notification-dropdown' />,
            <ProfileDropdown
              key='notification-profile'
              handleOpenModalEditUsername={handleOpenModalEditUsername}
            />,
            <SettingsDropdown key='notification-settings' />
          ]
        ),
        navLeftElements:
          hideNavElements || onlyTestBundleBooks ? (
            ''
          ) : (
            <>
              <VolumeControlWrapper>
                <VolumeControl />
              </VolumeControlWrapper>
              <TotalSaltyBucks />
              <LearningTimer />
            </>
          ),
        multipleCourse: courses.length > 1
      }
    : {
        redirectHandler: push,
        notification: getNotification(),
        showCrackUniversityLogo: R.propOr(false, 'has_courses', user),
        greeting: t('navigation.userGreeting', {
          username: R.propOr('', 'name', user)
        }),
        menu: t('navigation.menu'),
        links: getExamLinks(),
        onMenuOpen: () => handleClickOnMenuButton()
      }

  return (
    <>
      {/* @ts-ignore */}
      <TopNavigation {...courseStudentNavProps} />
      <ModalEditUsername
        open={openModalEditUsername}
        setOpen={setOpenModalEditUsername}
      />
      {isExpired && <ModalExpiredCourse />}
    </>
  )
}

export default AuthNav

const VolumeControlWrapper = styled.div`
  margin-left: 16px;
`
