import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'
import styled, { css } from 'styled-components'
import PATHS from 'utils/paths'
import qs from 'qs'

import {
  getExamDetails,
  getIsExamLoading,
  getSectionDetailsById
} from 'modules/exam/ducks/selectors'

import {
  getIsDiagnosticsLoading,
  getAverageQuestionsWorkingTimeGraph,
  getMaxWorkingQuestionAmount,
  getQuestionsData
} from 'modules/diagnostics/ducks/selectors'

import {
  fetchAverageQuestionsGraphDataRoutine,
  fetchQuestionsGraphDataRoutine,
  fetchTargetQuestionsGraphDataRoutine
} from 'modules/diagnostics/ducks/actions'

import { DEFAULT_ROWS_PER_PAGE, SORT_DIRECTION } from 'utils/table'
import { formatSecondsToMinutesAndSeconds, isDateBeforeToday } from 'utils/date'
import { isNotNilOrEmpty } from 'utils/ramda'
import {
  EXAM_STATUS,
  getPassageRealOrderById
  // SCORE_CALCULATION_METHOD
} from 'utils/exam'
import { RootReducer } from 'types'

import {
  EntitiesList,
  CheckmarkContainedIcon,
  CloseContainedIcon,
  Tooltip,
  // InfoTile,
  // YourScoreIcon,
  BackButton,
  BouncingLoader
} from 'examkrackers-components'
import AnswerSheetFloatingSummary from 'modules/diagnostics/components/AnswerSheetFloatingSummary'
import AnswerSheetFlagToggle from 'modules/diagnostics/components/AnswerSheetFlagToggle'
import { pathOr, propOr } from 'ramda'
import ExamScoreTabs from 'modules/diagnostics/components/ExamScoreTabs'
import AnalyticsService from '../../../services/AnalyticsService'
import { ANALYTICS_EVENTS } from '../../../utils/analytics'
import { getAccountType, getStudent } from '../../auth/ducks/selectors'

export const AnswerSheet = (): JSX.Element => {
  const params = useParams()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const history = useHistory()
  const {
    location: { search }
  } = history
  const parsedQuery = qs.parse(search, { ignoreQueryPrefix: true })
  const student = useSelector(getStudent)
  const accountType: string = useSelector(getAccountType)

  const sectionId: string = R.propOr('', 'sectionId')(params)
  const id: string = R.propOr('', 'id')(params)

  const questionsData = useSelector(getQuestionsData)
  const examDetails = useSelector(getExamDetails)
  const exam_id = R.pathOr('', ['exam', 'exam_id'])(examDetails)

  const isDiagnosticLoading = useSelector(getIsDiagnosticsLoading)
  const isExamLoading = useSelector(getIsExamLoading)
  const sectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, sectionId)
  )

  const redirectToExamsList = () => history.push(PATHS.exams)

  const accessPeriodEndDate = R.pathOr(
    '',
    ['exam', 'accessible_to'],
    examDetails
  )

  const sectionOrder = R.pipe(
    R.propOr([], 'questions'),
    R.find(R.propEq('id', sectionId)),
    R.propOr(0, 'order')
  )(examDetails)

  const sectionScore = R.pipe(
    R.propOr([], 'score_projection'),
    R.find(R.propEq('order', sectionOrder)),
    R.propOr(0, 'target_score')
  )(examDetails)

  // Get the questions graph data.
  const fetchQuestionsGraphData = React.useCallback(
    (sectionId: string) =>
      dispatch(fetchQuestionsGraphDataRoutine({ id: sectionId })),
    [dispatch]
  )

  // Get the target question graph data.
  const fetchTargetQuestionsGraphData = React.useCallback(
    (exam_id: string, section_order: string, section_score: number) =>
      dispatch(
        fetchTargetQuestionsGraphDataRoutine({
          exam_id,
          section_order,
          section_score
        })
      ),
    [dispatch]
  )

  // Get the average questions graph data.
  const fetchAverageQuestionsGraphData = React.useCallback(
    (exam_id: string, section_order: string) =>
      dispatch(
        fetchAverageQuestionsGraphDataRoutine({ exam_id, section_order })
      ),
    [dispatch]
  )

  React.useEffect(() => {
    fetchQuestionsGraphData(sectionId)
  }, [params])

  React.useEffect(() => {
    if (isNotNilOrEmpty(exam_id)) {
      // @ts-ignore
      fetchTargetQuestionsGraphData(exam_id, sectionOrder, sectionScore)
      // @ts-ignore
      fetchAverageQuestionsGraphData(exam_id, sectionOrder)
    }
  }, [examDetails, params])

  const headers = [
    {
      columnId: 'passage',
      sticky: true,
      sortable: false,
      id: 'passage',
      align: 'center',
      children: t('diagnostics.answerSheet.table.headers.passage')
    },
    {
      columnId: 'order',
      sticky: true,
      sortable: false,
      id: 'order',
      align: 'center',
      children: t('diagnostics.answerSheet.table.headers.question')
    },
    {
      columnId: 'correct_answer',
      sticky: true,
      sortable: false,
      id: 'correct_answer',
      align: 'center',
      children: t('diagnostics.answerSheet.table.headers.result')
    },
    {
      columnId: 'difficulty',
      sticky: true,
      sortable: false,
      id: 'difficulty',
      align: 'center',
      children: (
        <HeaderWithTooltip>
          <div>{t('diagnostics.answerSheet.table.headers.difficulty')}</div>
          <Tooltip
            id='difficulty-tooltip'
            place='bottom'
            info
            tooltipContent={
              <TooltipContent>
                {t('diagnostics.answerSheet.table.headers.difficultyTooltip')}
              </TooltipContent>
            }
          />
        </HeaderWithTooltip>
      )
    },
    {
      columnId: 'timeWorkingQuestion',
      sticky: true,
      sortable: false,
      id: 'timeWorkingQuestion',
      align: 'center',
      children: (
        <HeaderWithTooltip>
          <div>
            {t('diagnostics.answerSheet.table.headers.timeWorkingQuestion')}
          </div>
          <Tooltip
            id='time-tooltip'
            place='bottom'
            info
            tooltipContent={
              <TooltipContent>
                {t(
                  'diagnostics.answerSheet.table.headers.timeWorkingQuestionTooltip'
                )}
                <TimeWorkingLegend>
                  <span>
                    {t('diagnostics.answerSheet.table.headers.meaning')}:
                  </span>
                  <TimeWorkingLegendOption>
                    <div>{t('diagnostics.answerSheet.table.headers.you')}</div>
                    <LegendSolidLine />
                  </TimeWorkingLegendOption>
                  <TimeWorkingLegendOption>
                    <div>
                      {t('diagnostics.answerSheet.table.headers.average')}
                    </div>
                    <LegendDottedLine />
                  </TimeWorkingLegendOption>
                </TimeWorkingLegend>
              </TooltipContent>
            }
          />
        </HeaderWithTooltip>
      )
    },
    {
      columnId: 'is_flagged',
      sticky: true,
      sortable: false,
      id: 'is_flagged',
      align: 'center',
      children: t('diagnostics.answerSheet.table.headers.flag')
    },
    {
      columnId: 'action',
      sticky: true,
      sortable: false,
      id: 'action',
      align: 'center',
      children: t('diagnostics.answerSheet.table.headers.action')
    }
  ]

  const redirectToExplanations = (questionId: string, questionData) => () => {
    const passageId = R.pathOr('-', ['passage', 'id'], questionData)
    const passageNumber = getPassageRealOrderById(
      sectionDetails,
      passageId,
      '-'
    )
    const correctAnswer = R.propOr('', 'correct_answer', questionData)
    const userAnswer = R.propOr('', 'answer', questionData)
    const timeWorkingQuestion = R.pathOr(0, ['timers', 'working'], questionData)

    AnalyticsService(student).logEvent(ANALYTICS_EVENTS.singleAnswerReviewed, {
      'Exam Title': R.pathOr('', ['exam', 'title'], examDetails),
      'Exam format': R.pathOr('', ['type', 'title'], examDetails),
      'Account type': accountType,
      'Precentile Rank': R.pathOr(
        0,
        ['exam', 'scores', 'exam', 'percentile_rank'],
        examDetails
      ),
      'Raw Score': R.pathOr(
        0,
        ['exam', 'scores', 'exam', 'amount_correct'],
        examDetails
      ),
      'Scaled Score': R.pathOr(
        0,
        ['exam', 'scores', 'exam', 'scaled_score'],
        examDetails
      ),
      'Passage number': passageNumber,
      'Question number': R.propOr('-', 'order', questionData),
      Result: correctAnswer === userAnswer ? 'Correct' : 'Incorrect',
      Difficulty: R.pathOr(
        '0',
        ['original_question', 'difficulty_percentage'],
        questionData
      ),
      'Time Working Question':
        formatSecondsToMinutesAndSeconds(timeWorkingQuestion),
      'Section Title': R.propOr('-', 'title', sectionDetails),
      'Section Number': R.propOr(0, 'order', sectionDetails)
    })

    history.push(`/exam/${id}/explanations/${questionId}`)
  }

  const getActionButton = (data: any) => {
    if (isDateBeforeToday(accessPeriodEndDate)) {
      return (
        <ActionButton id={`${data.id}-expired`} disabled>
          {t('exams.actions.expired')}
        </ActionButton>
      )
    } else {
      return (
        <ActionButton
          id={`${data.id}-explanations-review`}
          onClick={redirectToExplanations(data.id, data)}
        >
          {t('exams.actions.view')}
        </ActionButton>
      )
    }
  }

  // Table rows
  const rows = questionsData.map((questionData: any) => ({
    id: `row-answer-sheet-question-${questionData.id}`,
    cells: [
      {
        columnId: 'passage',
        cellProps: { align: 'center' },
        children: (
          <>
            {getPassageRealOrderById(
              sectionDetails,
              R.pathOr('-', ['passage', 'id'], questionData),
              <DqItem>
                <div>{t('diagnostics.dq')}</div>
                <Tooltip
                  id='dq-tooltip'
                  info
                  tooltipContent={
                    <TooltipContent>
                      {t('diagnostics.discreetQuestion')}
                    </TooltipContent>
                  }
                />
              </DqItem>
            )}
          </>
        )
      },
      {
        columnId: 'order',
        cellProps: { align: 'center' },
        children: <>{R.propOr('-', 'order', questionData)}</>
      },
      {
        columnId: 'correct_answer',
        cellProps: {
          align: 'center'
        },
        children: (
          <QuestionCorrect
            answer={R.pathOr('-', ['answer'], questionData)}
            correctAnswer={R.pathOr('', ['correct_answer'], questionData)}
          />
        )
      },
      {
        columnId: 'difficulty',
        cellProps: {
          align: 'center',
          width: '20%'
        },
        children: (
          <>
            {R.pathOr(
              '0',
              ['original_question', 'difficulty_percentage'],
              questionData
            )}
            %
          </>
        )
      },
      {
        columnId: 'timeWorkingQuestion',
        cellProps: {
          align: 'center',
          width: '20%'
        },
        children: <TimeWorkingCell questionData={questionData} />
      },
      {
        columnId: 'is_flagged',
        cellProps: {
          align: 'center',
          width: '10%'
        },
        children: (
          <AnswerSheetFlagToggle
            isFlagged={R.propOr(false, 'is_flagged', questionData)}
            questionId={R.propOr('', 'id', questionData)}
            sectionId={sectionId}
          />
        )
      },
      {
        columnId: 'action',
        cellProps: { align: 'center' },
        children: (
          <ButtonContainer>{getActionButton(questionData)}</ButtonContainer>
        )
      }
    ]
  }))

  const sectionTitle = propOr('', 'title', sectionDetails)
  const examName = pathOr('', ['exam', 'title'], examDetails)
  const scores = R.pathOr([], ['exam', 'scores', 'sections'])(examDetails)
  const sectionScoreDetails = R.find(R.propEq('id', sectionId))(scores)
  const scaledScore = propOr(0, 'scaled_score', sectionScoreDetails)
  const percentileRank = propOr(0, 'percentile_rank', sectionScoreDetails)
  const totalAnswersAmount = propOr(0, 'total_amount', sectionScoreDetails)
  const correctAnswersAmount = propOr(0, 'amount_correct', sectionScoreDetails)

  // const scoreCalculationMethod = pathOr(
  //   '',
  //   ['exam', 'originalExam', 'score_calculation_method'],
  //   examDetails
  // )

  // const hideScaledScore =
  //   scoreCalculationMethod === SCORE_CALCULATION_METHOD.percentile

  // const scoreTile = hideScaledScore
  //   ? [
  //       <BackButton key='back-button' onClick={redirectToExamsList}>
  //         {t('diagnostics.answerSheet.backButton')}
  //       </BackButton>
  //     ]
  //   : [
  //       <InfoTile
  //         key='score-tile'
  //         icon={<YourScoreIcon />}
  //         content={
  //           <TileContent>
  //             <span>{t('diagnostics.answerSheet.scaled')}: </span>
  //             {scaledScore}
  //           </TileContent>
  //         }
  //       />,
  //       <BackButton key='back-button' onClick={redirectToExamsList}>
  //         {t('diagnostics.answerSheet.backButton')}
  //       </BackButton>
  //     ]

  const defaultRowsPerPage: number = R.pipe(
    R.pathOr(DEFAULT_ROWS_PER_PAGE, ['limit', 'take']),
    Number
  )(parsedQuery)

  return (
    <ExamScoreTabs
      activeTab='answer-sheet'
      saltyImageUrl='/assets/illustrations/SaltyWooHoo.svg'
      tiles={[
        <ExamName>{`${examName}: ${sectionTitle}`}</ExamName>,
        <BackButton key='back-button' onClick={redirectToExamsList}>
          {t('diagnostics.answerSheet.backButton')}
        </BackButton>
      ]}
    >
      <TableContainer>
        {isDiagnosticLoading || isExamLoading ? (
          <LoaderContainer>
            <BouncingLoader />
          </LoaderContainer>
        ) : (
          <EntitiesListContainer>
            {/* <ChartWithTilesContainer> */}
            <Tiles>
              <TilesWrapper>
                <ExamNameTile>{`${sectionTitle}`}</ExamNameTile>
                <StyledInfoTile key='rank-tile'>
                  <span>{t('diagnostics.answerSheet.rank')} </span>
                  {percentileRank}%{/* </TileContent> */}
                </StyledInfoTile>

                <StyledInfoTile key='raw-tile'>
                  <span>{t('diagnostics.answerSheet.raw')} </span>
                  {correctAnswersAmount} of {totalAnswersAmount}
                </StyledInfoTile>

                <StyledInfoTile key='score-tile'>
                  <span>{t('diagnostics.answerSheet.scaled')} </span>
                  {scaledScore}
                </StyledInfoTile>
              </TilesWrapper>
            </Tiles>
            <EntitiesList
              size='m'
              resultsText=''
              headers={headers}
              rows={R.ifElse(
                R.pathEq(['exam', 'status'], EXAM_STATUS.completed),
                R.always(rows),
                R.always([])
              )(examDetails)}
              totalPages={1}
              emptyStateText={t('diagnostics.answerSheet.table.emptyState')}
              defaultPage={1}
              defaultSortColumnId='question'
              defaultSortDirection={SORT_DIRECTION.asc}
              onTableStateChange={() => {}}
              highlight
              defaultRowsPerPage={defaultRowsPerPage as 10 | 50 | 100}
            />
            {R.ifElse(
              R.pathEq(['exam', 'status'], EXAM_STATUS.completed),
              R.always(<AnswerSheetFloatingSummary headers={headers} />),
              R.always(<></>)
            )(examDetails)}
          </EntitiesListContainer>
        )}
      </TableContainer>
    </ExamScoreTabs>
  )
}

// Component to render the state of an answered question.
const QuestionCorrect = ({ correctAnswer, answer }): JSX.Element => {
  const isCorrect: boolean = correctAnswer === answer

  return (
    <>
      {isCorrect ? (
        <CorrectContainer>
          <CheckmarkContainedIcon />
        </CorrectContainer>
      ) : (
        <IncorrectContainer>
          <CloseContainedIcon />
        </IncorrectContainer>
      )}
    </>
  )
}

const TimeWorkingCell = ({ questionData }): JSX.Element => {
  const answer = R.pathOr('', ['answer'], questionData)

  const correctAnswer = R.pathOr('', ['correct_answer'], questionData)

  const isCorrect = answer === correctAnswer
  const isSkipped = R.propEq('answer', null, questionData)

  const averageQuestionsWorkingTimeData = useSelector(
    getAverageQuestionsWorkingTimeGraph
  )
  const maxWorkingSeconds = useSelector(getMaxWorkingQuestionAmount)

  // @ts-ignore
  const findWorkingSecondsInMetrics: (any) => number = R.pipe(
    R.find(R.propEq('x', questionData.order)),
    R.propOr(0, 'y')
  )

  const workingSeconds: number = R.pathOr(
    0,
    ['timers', 'working'],
    questionData
  )

  return (
    <TimeWorkingCellContainer correct={isCorrect} skipped={isSkipped}>
      <span>{formatSecondsToMinutesAndSeconds(workingSeconds)}</span>
      <LineGraph>
        <SolidLine
          correct={isCorrect}
          skipped={isSkipped}
          workingLineWidth={(workingSeconds / maxWorkingSeconds) * 100}
        />
        <DottedLine
          averageWorkingLineWidth={
            (findWorkingSecondsInMetrics(averageQuestionsWorkingTimeData) /
              maxWorkingSeconds) *
            100
          }
        />
      </LineGraph>
    </TimeWorkingCellContainer>
  )
}

const CorrectContainer = styled.div`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.diagnostics.question.correct};

  path {
    fill: ${({ theme }) => theme.colors.diagnostics.question.correct};
  }
`

const IncorrectContainer = styled.div`
  font-size: 20px !important;
  color: ${({ theme }) => theme.colors.diagnostics.question.incorrect};
`

const EntitiesListContainer = styled.div`
  display: grid;
  grid-template-columns: 148px 1fr;
  background-color: ${({ theme }) => theme.colors.backgrounds.main};
  padding: 14px;
  & > div > div:nth-child(1) {
    margin: 0;
  }

  & > div > div:nth-child(2) {
    padding: 0;
    background-color: none !important;
    box-shadow: none !important;
  }

  & > div > div:nth-child(2) > div {
    overflow-y: auto;
    max-height: ${({ theme }) =>
      `calc(100vh - ${theme.dimensions.studentTopNavHeight} - 90px)`};
    margin-bottom: 44px;
  }

  .rows-per-page {
    /* margin-top: 28px; */
    display: none;
  }

  thead {
    background-color: ${({ theme }) => theme.colors.main.white};
  }
`

const TableContainer = styled.div`
  table {
    padding: 8px 16px;

    thead {
      position: sticky;
      top: 0;
      height: 40px;
      /* background: ${({ theme }) => theme.colors.main.white}; */
      z-index: 1;
    }

    td {
      line-height: 0.8;
      padding: 5px;
    }
  }

  @media only screen and (max-width: 1280px) {
    max-height: 400px;
  }
`

const LineGraph = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`

const Line = styled.div`
  margin-left: 10px;
  height: 2px;
  max-width: 100% !important;
`

const SolidLine = styled(Line)`
  width: ${({ workingLineWidth }) => workingLineWidth}%;
  border-bottom: 3px solid ${({ theme }) => theme.colors.main.text};
`

const DottedLine = styled(Line)`
  width: ${({ averageWorkingLineWidth }) => averageWorkingLineWidth}%;
  border-bottom: 3px dotted ${({ theme }) => theme.colors.main.text};
`

const TimeWorkingCellContainer = styled.div`
  display: flex;
  justify-content: center;
`

const LoaderContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 584px;
  height: calc(100vh - 140px);
  z-index: ${({ theme }) => theme.zIndex.menu};
  background: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: ${({ theme }) => theme.shadows.mainShadow};
  border-radius: 6px;
  align-items: center;
  justify-content: center;
  display: flex;
`

const ButtonContainer = styled.div`
  button {
    height: 28px;
  }
`

const HeaderWithTooltip = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & div:first-of-type {
    margin-right: 5px;
  }
`

export default AnswerSheet

const ActionButton = styled.div`
  cursor: pointer;
  text-decoration: underline;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      color: ${theme.colors.main.grey600};
      cursor: not-allowed;
    `}
`

const DqItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  & div:first-of-type {
    margin-right: 5px;
  }
`

// const TileContent = styled.div`
//   font-weight: bold;
//   font-size: 18px;

//   span {
//     font-weight: normal;
//     font-size: 14px;
//   }
// `

const TooltipContent = styled.div`
  font-size: 11px !important;
  line-height: 13px !important;
  font-weight: normal !important;
  max-width: 182px;
  white-space: pre-line;
  text-align: left;
  padding: 6px 0;
`

const TimeWorkingLegend = styled.div`
  font-size: 11px !important;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  span {
    font-weight: bold;
    display: block;
    margin-bottom: 3px;
  }
`

const TimeWorkingLegendOption = styled.div`
  display: flex;
  align-items: center;
  font-size: 11px !important;
  font-weight: normal;
  div {
    font-size: 11px !important;
  }
`

const LegendSolidLine = styled.div`
  width: 56px;
  border-bottom: 2px solid #fff;
`

const LegendDottedLine = styled.div`
  width: 56px;
  border-bottom: 2px dotted #fff;
`

const ExamName = styled.div`
  font-weight: bold;
  font-size: 22px;
`

const Tiles = styled.div`
  display: flex;
  flex-direction: column;
  padding: 48px 16px 16px;
  /* align-items: center; */
  /* background-color: ${({ theme }) => theme.colors.backgrounds.main}; */
  /* box-shadow: ${({ theme }) => theme.shadows.mainShadow}; */
  height: 100%;
  gap: 15px;
`

const TilesWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.backgrounds.main};
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  gap: 16px;
  padding: 8px;
`
const StyledInfoTile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffeedf;
  /* margin: 0 8px; */
  border-radius: 4px;
  padding: 4px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 20.7px;

  span {
    font-weight: normal;
    font-size: 14px;
  }
`

const ExamNameTile = styled.div`
  font-weight: 700;
  font-size: 16px;
  line-height: 18.4px;
  padding: 0 2px;
`
