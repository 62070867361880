import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import * as R from 'ramda'

import { ChartIcon, ToggleSwitch } from 'examkrackers-components'

import { getDiagnosticLegendState } from 'modules/diagnostics/ducks/selectors'
import { saveDiagnosticGraphLegendStateRoutine } from 'modules/diagnostics/ducks/actions'

import { EXAM_SECTION_DIFFICULTY_CHART_SERIES } from 'modules/diagnostics/utils/ExamSectionDifficultyChart'
import { EXAM_SECTION_TIME_CHART_SERIES } from 'modules/diagnostics/utils/ExamSectionTimeChart'
import AnalyticsService from '../../../services/AnalyticsService'
import {
  ANALYTICS_EVENTS,
  seriesNameToChartType
} from '../../../utils/analytics'
import { getAccountType, getStudent } from '../../auth/ducks/selectors'
import {
  getExamDetails,
  getSectionDetailsById
} from '../../exam/ducks/selectors'
import { useParams } from 'react-router-dom'
import { RootReducer } from '../../../types'

export const LegendExamSectionDiagnostics = (): JSX.Element => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const params = useParams()

  const diagnosticLegendState = useSelector(getDiagnosticLegendState)
  const student = useSelector(getStudent)
  const accountType: string = useSelector(getAccountType)
  const examDetails = useSelector(getExamDetails)
  const sectionId: string = R.propOr('', 'sectionId')(params)
  const sectionDetails = useSelector((state: RootReducer) =>
    getSectionDetailsById(state, sectionId)
  )

  const saveLegendState = React.useCallback(
    (seriesName, isVisible) =>
      dispatch(
        saveDiagnosticGraphLegendStateRoutine({
          // @ts-ignore
          ...diagnosticLegendState,
          [seriesName]: isVisible
        })
      ),
    [dispatch, diagnosticLegendState]
  )

  const handleSeriesToggle = seriesName => () => {
    const newValue = !isSeriesDataVisible(seriesName)

    AnalyticsService(student).logEvent(
      ANALYTICS_EVENTS.diagnosticChartsReviewed,
      {
        'Exam Title': R.pathOr('', ['exam', 'title'], examDetails),
        'Exam format': R.pathOr('', ['type', 'title'], examDetails),
        'Section Title': R.propOr('-', 'title', sectionDetails),
        'Section Number': R.propOr(0, 'order', sectionDetails),
        'Account type': accountType,
        'Chart Type': seriesNameToChartType(seriesName),
        'Visibility Status': newValue ? 'On' : 'Off'
      }
    )

    saveLegendState(seriesName, newValue)
  }

  const isSeriesDataVisible: (seriesName) => boolean = seriesName =>
    R.pipe(R.propOr(true, seriesName))(diagnosticLegendState)

  return (
    <Container>
      <div>
        <LegendTitle>{t('diagnostics.graph.legend.filters')}</LegendTitle>

        <Divider />

        <PassagesLegend>
          <ToggleContainer
            onClick={handleSeriesToggle(
              EXAM_SECTION_TIME_CHART_SERIES.passagesWorking
            )}
          >
            <PassageLabel>
              <ChartIcon id='working' />
              <Label>{t('diagnostics.graph.legend.timeWorkingPassage')}</Label>
            </PassageLabel>
            <ToggleSwitch
              checked={isSeriesDataVisible(
                EXAM_SECTION_TIME_CHART_SERIES.passagesWorking
              )}
              value={isSeriesDataVisible(
                EXAM_SECTION_TIME_CHART_SERIES.passagesWorking
              )}
            />
          </ToggleContainer>
          <ToggleContainer
            onClick={handleSeriesToggle(
              EXAM_SECTION_TIME_CHART_SERIES.passageReading
            )}
          >
            <PassageLabel>
              <ChartIcon id='reading' />
              <Label>{t('diagnostics.graph.legend.timeReadingPassage')}</Label>
            </PassageLabel>
            <ToggleSwitch
              checked={isSeriesDataVisible(
                EXAM_SECTION_TIME_CHART_SERIES.passageReading
              )}
              value={isSeriesDataVisible(
                EXAM_SECTION_TIME_CHART_SERIES.passageReading
              )}
            />
          </ToggleContainer>
        </PassagesLegend>

        <Divider />

        <QuestionsLegend>
          <Label>{t('diagnostics.graph.legend.timeWorkingQuestion')}</Label>
          <QuestionVariants>
            <ToggleContainer
              onClick={handleSeriesToggle(
                EXAM_SECTION_TIME_CHART_SERIES.timePerQuestion
              )}
            >
              <QuestionVariant>
                <You />
                <Label>{t('diagnostics.graph.legend.you')}</Label>
              </QuestionVariant>
              <ToggleSwitch
                checked={isSeriesDataVisible(
                  EXAM_SECTION_TIME_CHART_SERIES.timePerQuestion
                )}
                value={isSeriesDataVisible(
                  EXAM_SECTION_TIME_CHART_SERIES.timePerQuestion
                )}
              />
            </ToggleContainer>

            <ToggleContainer
              onClick={handleSeriesToggle(
                EXAM_SECTION_TIME_CHART_SERIES.averageTimePerQuestion
              )}
            >
              <QuestionVariant>
                <Average />
                <Label>{t('diagnostics.graph.legend.average')}</Label>
              </QuestionVariant>
              <ToggleSwitch
                checked={isSeriesDataVisible(
                  EXAM_SECTION_TIME_CHART_SERIES.averageTimePerQuestion
                )}
                value={isSeriesDataVisible(
                  EXAM_SECTION_TIME_CHART_SERIES.averageTimePerQuestion
                )}
              />
            </ToggleContainer>
          </QuestionVariants>
        </QuestionsLegend>

        <Divider />

        <DifficultyLegend>
          <ToggleContainer
            onClick={handleSeriesToggle(
              EXAM_SECTION_DIFFICULTY_CHART_SERIES.difficulty
            )}
          >
            <Label>{t('diagnostics.graph.legend.questionDifficulty')}</Label>
            <ToggleSwitch
              checked={isSeriesDataVisible(
                EXAM_SECTION_DIFFICULTY_CHART_SERIES.difficulty
              )}
              value={isSeriesDataVisible(
                EXAM_SECTION_DIFFICULTY_CHART_SERIES.difficulty
              )}
            />
          </ToggleContainer>
          <DifficultyVariants>
            <DifficultyVariant>
              <Correct />
              <DifficultyVariantLabel>
                {t('diagnostics.graph.legend.correct')}
              </DifficultyVariantLabel>
            </DifficultyVariant>
            <DifficultyVariant>
              <Incorrect />
              <DifficultyVariantLabel>
                {t('diagnostics.graph.legend.incorrect')}
              </DifficultyVariantLabel>
            </DifficultyVariant>
            <DifficultyVariant>
              <Skipped />
              <DifficultyVariantLabel>
                {t('diagnostics.graph.legend.skipped')}
              </DifficultyVariantLabel>
            </DifficultyVariant>
          </DifficultyVariants>
        </DifficultyLegend>
      </div>
      <SaltyImage src='/assets/illustrations/SaltyFacePalm.svg' />
    </Container>
  )
}

export default LegendExamSectionDiagnostics

const Container = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  position: relative;
`

const LegendTitle = styled.div`
  color: ${({ theme }) => theme.colors.main.grey600};
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`

const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ theme }) => theme.colors.main.grey300};
  margin: 8px 0;
`

const SaltyImage = styled.img`
  height: 75px;
  position: absolute;
  bottom: 16px;
  right: 16px;
`

const ToggleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  cursor: pointer;
`

const Label = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 16px;
`

const PassageLabel = styled.div`
  display: flex;
  gap: 8px;

  #working {
    color: ${({ theme }) =>
      theme.colors.diagnostics.diagnostic.passageWorking.chart};
  }

  #reading {
    color: ${({ theme }) =>
      theme.colors.diagnostics.diagnostic.passageReading.chart};
  }
`

const PassagesLegend = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 40px 0;
`

const QuestionsLegend = styled.div`
  margin: 40px 0;
`

const DifficultyLegend = styled.div`
  margin: 40px 0;
`

const DifficultyVariants = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 26px;
`

const DifficultyVariant = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const DifficultyVariantLabel = styled.div`
  color: ${({ theme }) => theme.colors.main.grey600};
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
`

const Correct = styled.div`
  display: inline-block;
  width: 2px;
  height: 9px;
  background: ${({ theme }) => theme.colors.diagnostics.question.correct};
`
const Incorrect = styled.div`
  display: inline-block;
  width: 2px;
  height: 9px;
  background: ${({ theme }) => theme.colors.diagnostics.question.incorrect};
`

const Skipped = styled.div`
  display: inline-block;
  width: 2px;
  height: 9px;
  background: ${({ theme }) => theme.colors.diagnostics.question.skipped};
`

const You = styled.div`
  border-top: 2px solid ${({ theme }) => theme.colors.main.heading};
  width: 56px;
`

const Average = styled.div`
  border-top: 2px dotted ${({ theme }) => theme.colors.main.heading};
  width: 56px;
`

const QuestionVariant = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const QuestionVariants = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 26px;
`
